<template>
    <div class="Home">
      <page-header/>
        <section class="uk-section uk-padding-remove">
            <div class="uk-section uk-margin-remove-bottom uk-padding-remove">
              <div class="uk-cover-container uk-margin-remove-top uk-padding-remove-top">
                <canvas style="width:100%; height:100%"></canvas>
                <img src="../../assets/404_page_cover.jpg" alt="" uk-cover>
              </div>
            </div>
        </section>
        <page-footer/>
    </div>
</template>
<script>
import PageHeader from '@/components/Homecmp/Header.vue'
import PageFooter from '@/components/Homecmp/Footer.vue'
export default {
components:{
  PageHeader,
  PageFooter 
}
}
</script>
<style scoped>

</style>